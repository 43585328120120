/* 스타일 기본 설정 */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  height: 100%;
  overflow-x: hidden; /* 좌우 스크롤을 없앰 */
  min-width: 320px;
}

.App {
  text-align: center;
}

.content-wrapper {
  flex: 1; /* 콘텐츠가 공간을 차지하도록 만듦 */
}

@media (max-width: 1300px) {
  .sticker-right {
    display: none; /* 1300px 이하에서 숨기기 */
  }
}

main {
  min-height: 100%;
  padding-top: 20px;
  position: relative;
  z-index: 2;
  margin-top: 100px;
  max-width: 1000px; /* 최대 너비 설정 */
  margin-left: auto; /* 중앙 정렬 */
  margin-right: auto; /* 중앙 정렬 */
  background-color: #ffffff; /* 배경색 설정 */
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding-top: 0;
    margin-top: 95px;
  }
}

.navigator {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
}

.left-logo {
  flex: 1;
}

.left-logo img {
  height: 40px;
}

.right-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 2;
}

.top-nav {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.top-nav nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.top-nav nav ul li {
  margin: 0;
}

.top-nav nav ul li a {
  text-decoration: none;
  color: #000;
  font-size: 14px;
}

.top-nav nav ul li a:hover {
  color: #ffa500;
}

.auth-links {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 20px;
}

.auth-links ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.auth-links ul li {
  margin: 0;
}

.auth-links ul li a {
  text-decoration: none;
  color: #000;
  font-size: 14px;
}

.auth-links ul li a:hover {
  color: #ffa500;
}

label {
  cursor: pointer;
  font-size: 14px;
  color: #000;
}

label:hover {
  color: #ffa500;
}

.main-nav {
  background-color: #333333;
  width: 100%;
  padding: 10px 0;
  z-index: 1000;
  position: fixed;
  top: 0;
}

.main-nav .rightNav {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.main-nav .rightNav li {
  margin: 0 20px;
}

.main-nav .rightNav li a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
}

.main-nav .rightNav li a:hover {
  color: #ffa500;
}

.main-nav .rightNav li.active a {
  color: #ffa500;
  font-weight: bold;
}

button {
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
}

button:hover {
  opacity: 0.5;
}

.top-nav nav ul li a,
.top-nav nav ul li a:visited {
  color: #000;
}

.main-nav .rightNav li a,
.main-nav .rightNav li a:visited {
  color: #fff;
}

ul {
  list-style-type: none;
}
